import OSS from 'ali-oss/dist/aliyun-oss-sdk.min';
import PubSub from 'jraiser/pubsub/1.2/pubsub';

import {
  getPartSize,
  generateOssConfig,
  setLocalFileInfo,
  getLocalFileInfo,
  clearLocalFileInfo,
  cleanStript
} from './utils';
import {getOssToken} from "@/page-home/api/home";

class UploadManager extends PubSub{
  constructor(uploadInfo, fileData, events = {}, config = {}) {
    super(events)
    this.uploadInfo = uploadInfo;
    this.fileData = fileData;

    // 用户设置，暂不开放
    this.parallel = config.threadCount;
    this.partSize = config.partSize;
    this.retryCount = typeof config.retryCount === 'number' ? config.retryCount : 3;

    this.statusCode = 1; // 文件的上传状态码：-1 已完成 0 上传中 1 未开始 2 暂停状态
    this.percentage = 0;
    this.id = fileData.id;
    this.isDeleted = false; // 从列表删除后改为true，用于判断stop回调里面是否要重新加到waitQueue
  }

  addRejectListener(reject) {
    this.reject = reject;
  }

  addResolveListener(resolve) {
    this.resolve = resolve;
  }

  // 修改文件信息
  updateFileData(fileData) {
    for (const key in fileData) {
      // 不允许将title改为为空字符串
      if (key === 'title') {
        if (typeof fileData.title !== 'string' || fileData.title.replace(/(^\s*)|(\s*$)/, '') === '') {
          continue;
        }
        this.fileData.title = cleanStript(fileData.title);
      } else {
        this.fileData[key] = fileData[key];
      }
    }
  }

  // 开始/继续文件上传
  _start() {
    if (this.statusCode === 2) { // 暂停状态
      this.statusCode = 0; // 上传中
      return this._multipartUpload();
    }
    this.statusCode = 0; // 上传中

    const { uploadInfo, fileData } = this;

    return getOssToken().then(res => {
      const data = res.data
      this.trigger('FileStarted', {
        uploaderId: this.id,
        fileData
      });
      // const filename = fileData.file.name;
      const filename = this.id + '.' + fileData.file.name.split('.').pop();
      const callback = JSON.parse(data.callback || 'null');
      console.log(filename);
      this.ossConfig = generateOssConfig(data);
      // 上传到OSS的name
      // this.filenameOss = uploadInfo.artId + '/' + filename.substring(filename.lastIndexOf('.'));
      this.filenameOss = fileData.artId + '/' + filename;
      // 上传回调
      if (callback !== null) {
        this.callbackBody = {
          url: callback.callbackUrl,
          body: callback.callbackBody,
          host: callback.callbackHost
        };
      }
      return this._multipartUpload();
    }).catch(err => {
      this._emitFileFailed({
        code: err.code,
        message: err.msg,
        type: 'InitUploadError',
      });
      return Promise.resolve({
        data: {
          uploader: this,
        },
        code: 101,
        message: err.msg
      });
    })
  }

  // 分片上传
  _multipartUpload() {
    this.ossClient = new OSS(this.ossConfig);

    // 从本地获取checkpoint
    const checkpoint = getLocalFileInfo(this.fileData.id);
    if (checkpoint) {
      checkpoint.file = this.fileData.file;
    }

    // 断点续传
    return new Promise((resolve, reject) => {
      this.ossClient.multipartUpload(
        this.filenameOss,
        this.fileData.file,
        {
          parallel: this.parallel,
          partSize: this.partSize || getPartSize(this.fileData.file.size),
          progress: this._updateProgress.bind(this),
          checkpoint,
          callback: this.callbackBody
        }
      ).then(() => {
        // 完成上传
        this._finish();
        return resolve({
          code: 100,
          message: `${this.fileData.title}完成上传`,
          data: {
            id: this.id
          },
        });
      }).catch((err) => {
        return this._handleMultipartUploadError(err, resolve, reject);
      });
    });
  }

  // catch到multipartUpload方法出错尝试有限次数的重传
  _retry(resolve) {
    this.retryCount--;
    // 返回新的promise，以便于判断所有文件上传结束
    return resolve({
      code: 107,
      message: '上传错误，正在重试',
      data: {
        uploader: this
      }
    });
  }

  _emitFileFailed(errData) {
    /**
     * @fires UploadManager#FileFailed
     */
    this.trigger('FileFailed', {
      uploaderId: this.id,
      errData,
      fileData: this.fileData
    });
  }

  // 处理catch到multipartUpload方法出错的情况
  _handleMultipartUploadError(err, resolve, reject) {
    console.log(err);
    // 取消/暂停上传
    console.log('分片上传失败，需要处理');
  }

  // 停止文件上传
  _stop() {
    if (this.statusCode !== 0) { // 上传中
      return this.resolve();
    }
    this.statusCode = 2; // 暂停状态
    if (!this.ossClient) {
      return this.resolve();
    }
    this.ossClient.cancel();
  }

  // 文件完成上传
  _finish() {
    this.statusCode = -1; // 已完成
    clearLocalFileInfo(this.fileData.id);
    this.percentage = 1;
    /**
     * @fires UploadManager#FileSucceed
     */
    this.trigger('FileSucceed', {
      uploaderId: this.id,
      fileData: this.fileData
    });
  }

  // 更新上传进度
  async _updateProgress(progress, checkpoint) {
    this.percentage = progress;
    /**
     * @fires UploadManager#FileProgress
     */
    this.trigger('FileProgress', {
      uploaderId: this.id,
      progress,
      fileData: this.fileData
    });
    // 保存checkpoint信息到localStorage
    setLocalFileInfo(this.fileData.id, checkpoint);
  }
}

export default UploadManager
