<template>
  <div>
    <el-upload ref="upload" action="#"
               :file-list="artFiles" :auto-upload="false"
               :show-file-list="false"
               :http-request="uploadHttpRequest"
               :on-change="handleFileChange">
      <el-button size="small" type="primary">选择文件</el-button>
      <div slot="tip" class="el-upload__tip">选择后请点击保存按钮完成数据保存</div>
    </el-upload>
    <div class="content">
      <template v-if="artFiles.length > 0">
        <div v-for="(item, index) in artFiles" :key="index" class="item-wrapper">
          <div class="item-upload-cover">
            <img class="img-cover" src="@/page-home/assets/file.png" />
            <div class="mask" v-if="item.upStatus != undefined && item.upStatus !== 2">
              <h2>{{item.upStatus | upStatusFilter}}</h2>
              <el-progress v-if="item.upStatus !== 0" type="circle"
                           :percentage="item.process"></el-progress>
            </div>
          </div>
          <div class="item-right-block">
            <div>文件名：{{item.name}}</div>
            <div>
              文件描述：
              <el-button v-if="item.upStatus !== 0 && !item.editDesc"
                         type="text" @click="handleEditDesc(item)">编辑</el-button>
              <el-button v-if="item.editDesc" type="success"
                         icon="el-icon-check" size="mini"
                         circle @click="handleDescSave(item)"></el-button>
              <el-input v-if="!item.completed || item.editDesc"
                        class="item-desc"
                        type="textarea"
                        :rows="2"
                        placeholder="请输入文件描述" resize="none"
                        rows="4"
                        v-model="item.description">
              </el-input>
              <div v-else>
                {{item.description}}
              </div>
            </div>
            <el-button class="btn-delete" icon="el-icon-delete"
                       @click="handleFileDelete(index)"></el-button>
          </div>
        </div>
      </template>
      <div v-else>
        暂无数据内容，请选择文件后点击保存按钮完成保存
      </div>
    </div>
    <el-alert v-if="!isAllUploadFinish" class="upload-alert"
              title="上传中关闭网页或浏览器，将中断文件的上传"
              type="warning" />
  </div>
</template>

<script>
  import {deleteArtFile, saveArtFile, updateArtFile} from "@/page-home/api/home";
  import {isContainFileMimeType} from "@/library/upload/utils";
  import ReviewUpload from "@/library/upload";
  export default {
    name: "FileUpload",
    data() {
      return {
        basicInfo: {},
        artFiles: [], // 上传的文件数组
        uploadIns: null,
        isAllUploadFinish: true
      }
    },

    filters: {
      upStatusFilter(val) {
        let txt
        switch (val) {
          case 0: {
            txt = '等待上传'
            break
          }
          case 1: {
            txt = '上传中'
            break
          }
          case 2: {
            txt = '上传完成'
            break
          }
          case 3: {
            txt = '上传失败'
            break
          }
        }
        return txt
      }
    },

    // file item包括：upstatus:上传状态，saved：是否数据库中，completed：上传完成
    methods: {
      uploadHttpRequest() {
        console.log('开始上传文件');
        const fileSetting = {
          artId: this.basicInfo.artId,
          title: ''
        };
        this.artFiles.forEach(item => {
          if (!item.saved || !item.completed) {
            // 已完成和已保存不上传
            this.uploadIns.addFile(item, {
              FileStarted: this.onFileStarted,
              FileProgress: this.onFileProgress,
              FileSucceed: this.onFileSucceed,
              FileFailed: this.onFileFailed,
              FileStopped: this.onFileStopped,
            }, fileSetting)
            item.upStatus = 1
            this.$set(item, 'process', 0)
          }
        })
        if (this.uploadIns.fileQueue.size > 0) {
          this.isAllUploadFinish = false
          this.$message.success('保存成功，准备上传文件')
        } else {
          this.$message.success('保存成功')
          this.$store.commit('SET_USERUPLOAD_ACTIVE_TAB', 'third')
          this.$router.replace('/user/upload')
        }
        this.uploadIns.startAll()
      },

      handleFileChange(file) {
        if (isContainFileMimeType(file.raw)) {
          this.$set(file, 'description', '')
          this.$set(file, 'upStatus', 0)
          this.$set(file, 'completed', false)
          this.artFiles.push(file)
        } else {
          this.$message.error('文件类型不在接受范围内')
          return false
        }
      },

      handleFileDelete(index) {
        const item = this.artFiles[index]
        console.log(item);
        if (item.saved) {
          this.$confirm('是否删除当前作品的文件?', '提示', {
            confirmButtonText: '继续删除',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            const payload = {
              id: item.id,
              md5Id: item.md5Id,
              artId: this.basicInfo.artId,
              version: this.basicInfo.version
            }
            deleteArtFile(payload).then(res => {
              this.$message.success('文件删除成功')
              this.artFiles.splice(index, 1)
            }).catch(err => {
              this.$message.error('文件删除失败，请重试')
            })
          })
        } else {
          if (item.upStatus === 1) {
            this.$message.warning('文件上传中，即将删除')
            this.uploadIns.removeFile(item.idGen)
          }
          this.artFiles.splice(index, 1)
        }
      },

      submitFile() {
        // this.$refs['upload'].submit()
        this.uploadHttpRequest()
      },

      handleEditDesc(item) {
        item.editDesc = true
      },

      handleDescSave(item) {
        let payload = {}
        Object.assign(payload, item)
        payload.artId = this.basicInfo.artId
        payload.version = this.basicInfo.version
        updateArtFile(payload).then(res => {
          item.editDesc = false
          this.$message.success('文件描述修改成功')
        })
      },

      /* 以下为上传相关方法 */
      onUploadComplete() {
        console.log('所有文件上传结束！');
        this.isAllUploadFinish = true
        this.$message.success('所有文件上传结束')
        this.$router.replace('/user/upload')
      },
      onError(err) {
        if (err.code) {
          // 110：文件重复，111：拦截文件类型不在acceptedMimeType中的文件
          let errMsg = `（错误代码：${err.code}）${err.message}`;
          if (err.code === 110 || err.code === 111) {
            errMsg += ` ${err.data.filename}`;
          }
          this.$message.error(errMsg)
        } else {
          console.info(err);
        }
      },

      onFileStarted({ uploaderId, fileData }) {
        console.info('开始上传', uploaderId, fileData);
      },
      onFileProgress({ uploaderId, progress }) {
        const p = Number((progress * 100).toFixed(2))
        for (let item of this.artFiles) {
          if (item.idGen === uploaderId) {
            item.process = p
            break
          }
        }
      },
      onFileSucceed({ uploaderId, fileData }) {
        for (let item of this.artFiles) {
          if (item.idGen === uploaderId) {
            console.log(item);
            console.log(fileData);
            item.upStatus = 2
            item.completed = true
            const payload = {
              md5Id: item.idGen,
              name: item.name,
              artId: this.basicInfo.artId,
              version: this.basicInfo.version,
              fileSize: item.size,
              description: item.description,
              typeStr: item.raw.type
            }
            saveArtFile(payload).then(res => {
              item.completed = true
            })
            break
          }
        }
      },
      onFileFailed({ uploaderId, errData }) {
        console.info('上传失败', errData);
        for (let item of this.artFiles) {
          if (item.idGen === uploaderId) {
            item.upStatus = 3
            break
          }
        }
      },
      onFileStopped({ uploaderId, fileData }) {
        console.info('暂停上传 ' + uploaderId);
      },
    },

    created() {
      this.uploadIns = new ReviewUpload({
        parallelFileLimit: 3,
        // parallel: 3,
        events: {
          UploadComplete: this.onUploadComplete,
          Error: this.onError
        }
      })
    },
    beforeDestroy() {
      this.uploadIns = null
    }
  }
</script>

<style lang="scss" scoped>
  .content {
    min-height: 300px;
    padding: 20px;
    border: 1px solid gray;
    border-radius: 6px;
  }

  .item-wrapper {
    display: flex;
    width: 80%;
    height: 200px;
    margin: 10px auto;
    padding-bottom: 14px;
    border-bottom: 1px solid #e1e1e1;
    .item-upload-cover {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 200px;
      margin-right: 30px;
      .img-cover {
        width: 150px;
        height: 150px;
      }
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: white;
        background-color: rgba(12, 12, 12, 0.3);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      /deep/ {
        .el-progress__text {
          color: #fff;
        }
      }
    }
    .item-right-block {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .btn-delete {
    position: absolute;
    right: 0;
  }

  .item-desc {
    margin-top: auto;
  }

  .upload-alert {
    margin-top: 20px;
  }
</style>
