import PubSub from 'jraiser/pubsub/1.2/pubsub'

import { Queue } from './queue';
import { Pool } from './pool';
// 队列的上传状态
const STATUS = {
  NOT_STARTED: 'notStarted',
  UPLOADING: 'uploading',
}
import {
  generateFileData,
  isContainFileMimeType
} from './utils';
import UploadManager from "@/library/upload/upload";

class ReviewUpload extends PubSub {
  constructor(config = {}) {
    super(config.events)
    this.config = {
      partSize: config.partSize, // 分片大小，不能小于100k。单位为Bytes。默认按文件大小自动分片
      threadCount: config.threadCount, // 上传并发线程数
      retryCount: config.retryCount, // 网络原因失败时，重新上传次数
      acceptedMimeType: config.acceptedMimeType, // 用户自定义在一定范围内允许上传的文件类型
    }

    let parallelFileLimit = config.parallelFileLimit || 5; // 并行上传的文件数目；最大5个
    if (parallelFileLimit < 0 || parallelFileLimit > 5) {
      parallelFileLimit = 5;
    }
    // 文件队列
    this.fileQueue = new Queue();

    // 已添加但未允许开始上传的队列（未点击开始按钮或是暂停状态）
    this.waitQueue = new Queue();

    // 上传队列
    this.uploadPool = new Pool(uploader => uploader._start(), parallelFileLimit);

    this.uploadInfo = {};

    // 未添加then的promise数组，监控各文件的上传情况
    this.newUploadPromiseList = [];

    // 整个文件队列的上传状态
    this.status = STATUS.NOT_STARTED;
  }

  addFile(fileObj, events = {}, fileSetting = {}) {
    const fileData = generateFileData(fileObj.raw, fileSetting, this.uploadInfo);
    fileObj.idGen = fileData.id
    // 拦截重复文件
    if (this.fileQueue.find(fileData.id)) {
      this._emitError({
        code: 110,
        message: '文件重复',
        data: {
          filename: fileData.title,
        }
      });
      return null;
    }

    // 在fileList中判断，此处注释
    // 拦截文件类型不在acceptedMimeType中的文件
    // if (!isContainFileMimeType(file, this.config.acceptedMimeType)) {
    //   this._emitError({
    //     code: 111,
    //     message: '文件类型错误',
    //     data: {
    //       filename: fileData.title
    //     }
    //   });
    //   return null;
    // }

    this.fileQueue.enqueue(fileData);
    const uploader = new UploadManager(this.uploadInfo, fileData, events, this.config);
    if (this.status === STATUS.NOT_STARTED) {
      this.waitQueue.enqueue(uploader);
    } else {
      this.newUploadPromiseList.push(this.uploadPool.enqueue(uploader));
    }
    /** @type {UploadManager} */
    return uploader;
  }

  /**
   * 删除指定文件
   * @param {String} id 文件id，和对应的UploadManager实例的id一致
   */
  removeFile(id) {
    const uploader = this.uploadPool.remove(id);
    if (uploader) {
      uploader.isDeleted = true;
      uploader._stop();
    } else {
      // 同一个文件不能同时存在于waitQueue和uploadPool
      this.waitQueue.remove(id);
    }
    this.fileQueue.remove(id);
  }

  /**
   * 开始/继续上传指定文件
   * @param {String} id 文件id，和对应的UploadManager实例的id一致
   */
  resumeFile(id) {
    const uploader = this.waitQueue.remove(id);
    if (!uploader) {
      return;
    }
    this.newUploadPromiseList.push(this.uploadPool.enqueue(uploader));
    if (this.status === STATUS.NOT_STARTED) {
      this._onPromiseEnd();
    }
  }

  /**
   * 暂停上传指定文件
   * @param {String} id 文件id
   */
  stopFile(id) {
    const uploader = this.uploadPool.remove(id);
    if (uploader) {
      uploader._stop();
      this.waitQueue.enqueue(uploader);
    }
  }

  /**
   * 清空文件列表
   */
  clearAll() {
    this._stopAll(true); // 此时已清空uploadPool
    this.waitQueue.clear();
    this.fileQueue.clear();
  }

  startAll() {
    while (this.waitQueue.size > 0) {
      const uploader = this.waitQueue.dequeue();
      if (uploader.statusCode !== -1) {
        this.newUploadPromiseList.push(this.uploadPool.enqueue(uploader));
      }
    }
    this.status = STATUS.UPLOADING;
    this._onPromiseEnd();
  }

  /**
   * 停止上传所有文件
   */
  stopAll() {
    this._stopAll();
  }

  // 停止上传，根据参数决定是否删除该文件
  _stopAll(isDeleted = false) {
    while (this.uploadPool.size > 0) {
      const uploader = this.uploadPool.dequeue();
      if (isDeleted) {
        uploader.isDeleted = true;
      }
      uploader._stop();
      // 未开始上传的文件按原顺序放到waitQueue
      if (uploader.statusCode === 1) {
        this.waitQueue.enqueue(uploader);
      }
    }
    this.status = STATUS.NOT_STARTED;
  }

  // 监听所有上传promise
  _onPromiseEnd() {
    const uploadPromiseList = [...this.newUploadPromiseList];
    this.newUploadPromiseList = [];

    // 判断所有文件上传是否结束
    Promise.all(uploadPromiseList)
      .then(() => {
        if (this.newUploadPromiseList.length > 0) { // 还有未监听到的promise
          this._onPromiseEnd();
        } else if (this.uploadPool.size === 0) {
          this.status = STATUS.NOT_STARTED;

          if (this.waitQueue.size === 0 && this.fileQueue.size !== 0) {
            // 所有文件上传结束
            this.trigger('UploadComplete');
          }
        }
      });

    // 处理文件上传状态发生改变或上传报错的情况
    for (let i = 0; i < uploadPromiseList.length; i++) {
      uploadPromiseList[i]
        .then(res => {
          if (!res || !res.code) {
            return;
          }
          this._handleUploadStatusChange(res);
        })
        .catch(err => {
          this._emitError(err);
        });
    }
  }
  _emitError(err) {
    /**
     * 文件上传出错
     * @fires PlvVideoUpload#Error
     */
    this.trigger('Error', err);
  }
  // 文件上传状态发生改变
  _handleUploadStatusChange(res) {
    const data = res.data;
    switch (res.code) {
      case 100: { // 完成上传
        this.waitQueue.remove(data.id);
        break;
      }
      case 102: { // 用户剩余空间不足
        this.waitQueue.unshift(data.uploader);
        this._emitError(res);
        break;
      }
      case 101: // init请求失败，或，multipartUpload上传失败
      case 104: // 暂停上传
      case 105: { // Multipart Upload ID 不存在
        if (data.uploader && !data.uploader.isDeleted) {
          this.waitQueue.unshift(data.uploader);
        }
        break;
      }
      case 106: // token过期，正在重试
      case 107: { // 上传错误，正在重试
        this.newUploadPromiseList.push(this.uploadPool.enqueue(data.uploader));
        if (this.status === STATUS.NOT_STARTED) {
          this._onPromiseEnd();
        }
        break;
      }
      default:
        break;
    }
  }
}

export default ReviewUpload
