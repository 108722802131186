import axios from './axios'

export function userLogin(payload) {
  return axios.post('/api/admin/public/auth/login', payload)
}

export function refreshAdminToken(payload) {
  return axios.post('/api/admin/public/auth/token/refresh', payload)
}

export function changeAdminPwd(payload) {
  return axios.post('/api/admin/auth/changePwd', payload)
}

export function adminLogout() {
  return axios.post('/api/admin/auth/logout')
}

export function getOssToken() {

}
