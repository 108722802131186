const ACCESS_TOKEN_KEY = 'ADMIN_ACCESS_TOKEN'
const REFRESH_TOKEN_KEY = 'ADMIN_REFRESH_TOKEN'

export function getToken(type) {
  if (type === 'access') {
    return sessionStorage.getItem(ACCESS_TOKEN_KEY)
  }
  if (type === 'refresh') {
    return sessionStorage.getItem(REFRESH_TOKEN_KEY)
  }
}

export function setToken(type, token) {
  if (type === 'access') {
    return sessionStorage.setItem(ACCESS_TOKEN_KEY, token)
  }
  if (type === 'refresh') {
    return sessionStorage.setItem(REFRESH_TOKEN_KEY, token)
  }
}

export function removeAllToken() {
  sessionStorage.removeItem(ACCESS_TOKEN_KEY)
  sessionStorage.removeItem(REFRESH_TOKEN_KEY)
}
