import axios from './axios'

export function userList(payload) {
  return axios.post('/api/admin/user/list', payload)
}

// 禁用/启用用户
export function userLockChange(payload) {
  return axios.post('/api/admin/user/banned/switch', payload)
}

export function expertList(payload) {
  return axios.post('/api/admin/expert/list', payload)
}

export function expertAdd(payload) {
  return axios.post('/api/admin/expert/add', payload)
}

export function expertUpdate(payload) {
  return axios.post('/api/admin/expert/update', payload)
}

export function expertDelete(payload) {
  return axios.post('/api/admin/expert/delete', payload)
}

export function expertStatusChange(payload) {
  return axios.post('/api/admin/expert/lock/switch', payload)
}

export function categoryList(payload) {
  return axios.post('/api/admin/category/list', payload)
}

export function categoryAdd(payload) {
  return axios.post('/api/admin/category/add', payload)
}

export function categoryUpdate(payload) {
  return axios.post('/api/admin/category/update', payload)
}

export function categoryDelete(id) {
  return axios.post('/api/admin/category/delete', {id})
}

export function artList(payload) {
  return axios.post('/api/admin/art/list', payload)
}

export function adminArtDetail(payload) {
  return axios.post('/api/admin/art/detail', payload)
}
