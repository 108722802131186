<template>
  <div class="upload-wrapper">
    <el-page-header class="back-header" @back="handleBack" content="上传数据"></el-page-header>
    <el-steps class="upload-step" :active="stepActive" simple>
      <el-step title="填写内容" icon="el-icon-edit"></el-step>
      <el-step title="专家评审" icon="el-icon-upload"></el-step>
      <el-step title="完成" icon="el-icon-picture"></el-step>
    </el-steps>
    <div v-if="artDetail.status < 2" class="data-form-block">
      <el-form ref="artForm" :model="artForm" :rules="rules" label-width="100px">
        <el-form-item label="数据标题" prop="title">
          <el-input v-model="artForm.title" placeholder="请输入数据标题"></el-input>
        </el-form-item>
        <el-form-item label="数据封面" prop="coverImg">
          <div v-if="artDetail.coverImg" style="display: flex;">
            <el-image class="img-art-cover"
                      :src="artDetail.coverImg" fit="contain"></el-image>
            <el-upload ref="upload2" action="#"
                       :show-file-list="false"
                       :multiple="false"
                       :http-request="uploadArtCover"
                       :before-upload="beforeCoverUpload">
              <el-button class="m-l-20" size="small" type="primary">修改封面</el-button>
            </el-upload>
          </div>
          <el-upload
                  v-else
                  class="upload-avatar"
                  ref="upload"
                  drag
                  accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                  list-type="picture"
                  :multiple="false"
                  :show-flie-list="false"
                  action="#"
                  :http-request="uploadArtCover"
                  :before-upload="beforeCoverUpload"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将封面图片拖到此处，或<em>点击上传</em></div>
          </el-upload>

        </el-form-item>
        <el-form-item label="数据描述" prop="description">
          <el-input v-model="artForm.description" placeholder="请输入数据描述"
                    type="textarea"
                    :show-word-limit="true"
                    rows="4"
                    resize="none"></el-input>
        </el-form-item>
        <el-form-item label="数据分类" prop="cateId">
          <el-select v-model="artForm.cateId"
                     placeholder="请选择数据分类">
            <el-option
                    v-for="item in cates"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据内容">
          <file-upload ref="fileUpload"></file-upload>
        </el-form-item>
        <el-form-item>
          <el-tooltip class="tooltip" effect="light" placement="top-start"
                      content="点击保存后，后续仍然可以修改，提交审核后无法修改">
            <i class="el-icon-question" />
          </el-tooltip>
          <el-button style="width: 140px;" :disabled="loading"
                     @click="handleSaveClick">保存</el-button>
          <el-button style="width: 140px;" :disabled="loading"
                     @click="handleAuditClick">提交审核</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-else-if="artDetail.status === 2">
      <h2 class="tip-expert-review">专家评审中，请等待审核完成</h2>
    </div>
  </div>
</template>

<script>
  import {
    getAllCategory,
    getUserArtDetailByArtId,
    initUpload,
    saveArt,
    saveArtFile,
    submitArt,
    uploadImg
  } from "@/page-home/api/home";
  import FileUpload from "@/page-home/views/upload/components/FileUpload";
  import {Loading} from '@/library/element-ui/lib'
  export default {
    name: "UploadCreatePage",
    components: {FileUpload},
    data() {
      return {
        loading: false,
        artId: '',
        artVersion: 1,
        artDetail: {},
        artForm: {
          title: '',
          cateId: '',
          description: '',
          coverImg: ''
        },
        rules: {
          title: [
            {required: true, message: '请输入数据标题', trigger: ['blur', 'change']},
          ],
          description: [
            {required: true, message: '请输入数据描述', trigger: ['blur', 'change']},
          ],
          coverImg: [
            {required: true, message: '请上传封面图片'},
          ],
          cateId: [
            {required: true, message: '请选择数据分类', trigger: 'change'},
          ]
        },
        cates: []
      }
    },
    computed: {
      stepActive() {
        if (this.artDetail.status < 2) return 1
        if (this.artDetail.status === 2) return 2
        return 1
      }
    },

    methods: {
      // 头像上传
      beforeCoverUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!')
        }
        return isLt2M
      },
      // 上传图片时，保存
      uploadArtCover(params) {
        let uploadData = new FormData()
        uploadData.append('file', params.file)
        uploadData.append('artId', this.artId)
        let loadingInstance = Loading.service({
          target: '.data-form-block',
          text: '封面上传'
        })
        uploadImg(uploadData).then(res => {
          this.artForm.artId = this.artId
          saveArt(this.artForm).then(res2 => {
            this.$set(this.artDetail, 'coverImg', res.data.domain + '/' + res.data.path)
            this.artForm.coverImg = res.data.domain + '/' + res.data.path
            loadingInstance.close()
            this.$message.success('数据已保存')
          })
        })
      },
      /*******/
      getAllCategoryFromApi() {
        getAllCategory().then(res => {
          this.cates = res.data || []
        })
      },

      handleSaveClick() {
        if (this.loading) return
        // 点击后开始进行上传
        this.$refs['artForm'].validate(valid => {
          if (valid) {
            this.loading = true
            this.artForm.artId = this.artId
            saveArt(this.artForm).then(res => {
              this.uploadInfo = res.data
              this.$refs['fileUpload'].submitFile()
            }).catch(err => {
              this.$message.error('保存失败，请重试')
            }).finally(() => {
              this.loading = false
            })
          } else {
            return
          }
        })
      },

      handleAuditClick() {
        if (this.loading) return
        this.$refs['artForm'].validate(valid => {
          if (valid) {
            let count = 0
            this.$refs['fileUpload'].artFiles.forEach(item => {
              if (item.completed || item.saved || item.status === 2) {
                count++
              }
            })
            if (count < 1) {
              this.$message.error('您未上传文件，无法提交审核')
              return
            }
            this.loading = true
            const payload = {
              artId: this.artId,
              version: 1
            }
            submitArt(payload).then(res => {
              this.$message.success('提交审核成功，审核完成后会收到通知')
            }).finally(() => {
              this.loading = false
            })
          } else {
            return
          }
        })
      },

      handleBack() {
        this.$router.go(-1)
      },
    },

    created() {
      const params = this.$route.query
      this.artId = params.id
      this.artVersion = params.v || 1
      this.getAllCategoryFromApi()
    },

    mounted() {
      if (this.artId) {
        getUserArtDetailByArtId({artId: this.artId}).then(res => {
          this.artDetail = res.data
          this.artForm.title = this.artDetail.title
          this.artForm.description = this.artDetail.description
          this.artForm.coverImg = this.artDetail.coverImg
          if (this.artDetail.cateId > 0) {
            this.artForm.cateId = this.artDetail.cateId
          }
          if (this.artDetail.status < 2) {
            this.$nextTick(() => {
              this.artDetail.files.forEach(file => {
                this.$refs['fileUpload'].artFiles.push({
                  id: file.id,
                  name: file.name,
                  description: file.description,
                  completed: true,
                  saved: true,
                  editDesc: false,
                  md5Id: file.md5Id
                })
              })
              this.$refs['fileUpload'].basicInfo = {
                artId: this.artId,
                version: this.artVersion
              }
            })
          }
        })
      } else {
        this.$message.error('数据加载错误')
        this.$router.replace('/user/upload')
        return
      }
    }
  }
</script>

<style lang="scss" scoped>
  .upload-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
    .upload-step {
      max-width: 1000px;
      margin: 20px auto;
    }
  }
  .back-header {
    padding: 18px 16px;
    background-color: #ffffff;
  }

  .img-art-cover {
    width: 160px;
    height: 120px;
  }

  .tooltip {
    margin-right: 20px;
  }

  .tip-expert-review {
    margin-top: 100px;
    text-align: center;
  }
</style>
<style lang="scss">
  .el-upload__tip {
    display: inline-block;
    margin-left: 20px;
  }
</style>
