import axios from 'axios'
import { Loading, Message, Notification } from '@/library/element-ui/lib'
import {getToken, setToken, removeAllToken} from "@/page-admin/utils/storage";
import {refreshAdminToken} from "@/page-admin/api/auth";
import store from "@/page-home/store";
import router from "@/page-home/router";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
})

let isRefreshing = false
let requests = []

axiosInstance.interceptors.request.use(
  config => {
    if (config.method === 'get') {
      //  给data赋值以绕过if判断,解决get没有设置Content-Type
      config.data = true
    }
    const token = getToken('access')
    config.headers['X-Token'] = token ? 'A-' + token : ''
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  response => {
    let res = response.data
    if (res.code === 0) {
      return res
    } else if (res.code === 1) {
      // 尝试刷新token
      const config = response.config
      if (!isRefreshing) {
        isRefreshing = true
        return handleRefreshToken().then(data => {
          requests.forEach(cb => cb(data.accessToken))
          requests = []
          isRefreshing = false
          return axiosInstance.request(config)
        }).catch(err => {
          removeAllToken()
          location.reload()
          return Promise.reject({msg: 'error'})
        })
      } else {
        // 正在登录，其他请求存起来
        return new Promise((resolve) => {
          requests.push((token) => {
            config.headers['X-Token'] = token
            resolve(axiosInstance.request(config))
          })
        })
      }
      return Promise.reject({msg: 'error'})
    } else if (res.code === 2) {
      // 2无法刷新，重新登录
      removeAllToken()
      Message({
        message: res.msg,
        type: 'error',
      })
      location.reload()
    } else {
      return Promise.reject(res)
    }
  },
  error => {
    if (error.response) {
      const status = error.response.status
      const tips = status in httpCode ? httpCode[status] : error.response.data.message
      Notification({
        title: '错误',
        message: tips,
        type: 'error',
      })
      return Promise.reject(error)
    } else {
      Notification({
        title: '错误',
        message: '请求超时, 请刷新重试',
        type: 'error'
      })
      return Promise.reject({msg: '请求超时, 请刷新重试'})
    }
  }
)

const httpCode = {
  400: '请求参数错误',
  401: '权限不足，请重新登录',
  403: '服务器拒绝本次访问',
  404: '请求资源未找到',
  500: '内部服务器错误，请稍后重试',
  501: '服务器不支持该请求中使用的方法',
  502: '网关错误，请稍后重试',
  504: '网关超时，请稍后重试'
}

const handleRefreshToken = () => {
  const refreshToken = getToken('refresh')
  const accessToken = getToken('access')
  if (!refreshToken || !accessToken) {
    return Promise.reject({msg: '请重新登录'})
  }
  const payload = {
    accessToken: accessToken,
    refreshToken
  }
  return refreshAdminToken(payload).then(res => {
    removeAllToken()
    setToken('access', res.data.accessToken)
    setToken('refresh', res.data.refreshToken)
    return res
  })
}

export default axiosInstance
