<template>
  <div class="page-wrapper">
    <art-detail detail-type="user"></art-detail>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import ArtDetail from "@/components/ArtDetail/index";
  export default {
    name: "UploadDetailPage",
    components: {ArtDetail},
    data() {
      return {
        artId: '',
        version: 1,
        loading: false
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.userInfo
      })
    },

    methods: {
      handleBack() {
        this.$router.go(-1)
      }
    },

    created() {

    }
  }
</script>

<style lang="scss" scoped>
  .page-wrapper {
    padding: 30px;
  }

  .demo {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
